/**
  * 停止 / 开始自动播放
*/
export const swiperStartAutoPlay = (swiwperInstance) => {
  return swiwperInstance?.autoplay?.start()
}
export const swiperStopAutoPlay = (swiwperInstance) => {
  return swiwperInstance?.autoplay?.stop()
}
export const swiperSetTranslate = (swiperInstance, offset) => {
  swiperInstance?.setTransition(0)
  swiperInstance?.setTranslate(offset)
}
